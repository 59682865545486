import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProjectComponent } from './projects/project/project.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { ProjectsComponent } from './projects/projects.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { TechnologiesComponent } from './technologies/technologies.component';
import { FilterUniquePipe } from './utils/filter-unique-pipe';
import { AboutMeComponent } from './about-me/about-me.component';
import { HttpClientModule } from '@angular/common/http';
import { AppService } from './app.service';
import { PrintableComponent } from './printable/printable.component';

@NgModule({
  declarations: [
    AppComponent,
    ProjectComponent,
    NavigationBarComponent,
    ProjectsComponent,
    TechnologiesComponent,
    FilterUniquePipe,
    AboutMeComponent,
    PrintableComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, AppService],
  bootstrap: [AppComponent]
})
export class AppModule { }

export enum MainTec {
    JAVA = ('Java'),
    SPRING = ('Spring'),
    ANGULAR = ('Angular'),
    PYTHON = ("Python"),
    PHP = ("PHP"),
    JS = ("Javascript")
}

export enum TecCat{
    PL = ('Programming/Scripting Language'),
    JF = ('Java Frameworks'),
    JSF = ('JavaScript/Frameworks'),
    DB = ('Data Bases/Persistence'),
    MISC = ('Tools (CI/CD, Build, Misc.)'),
    BD = ('Big Data')
}

export class Technology {
  mainTech: MainTec;
  techName: string;
  techCat: TecCat;
  techLev: number;
}

export class ProjectTechnology {
  id: number;
  name: string;
  technologies: string[]
}

export class TecRef { 
    mainDesc: string;
    mainDesc_de: string;
    mainDesc_es: string;
    techLev: number;
}


export const techMap: Map<string, Technology> = new Map([
    ['kot', {mainTech : MainTec.JAVA, techName : 'Kotlin', techCat : TecCat.PL, techLev : 4}],
    ['j8', {mainTech : MainTec.JAVA, techName : 'Java 8+', techCat : TecCat.PL, techLev : 4}],
    ['j7', {mainTech : MainTec.JAVA, techName : 'Java 5/7', techCat : TecCat.PL, techLev : 4}],
    ['sb', {mainTech : MainTec.SPRING, techName : 'Spring Boot', techCat : TecCat.JF, techLev : 4}],
    ['sp', {mainTech : MainTec.SPRING, techName : 'Spring Core/MVC/Data', techCat : TecCat.JF, techLev : 4}],
    ['st', {mainTech : null, techName : 'Jakarta Struts', techCat : TecCat.JF, techLev : 2}],
    ['jso', {mainTech : null, techName : 'JSoup', techCat : TecCat.JF, techLev : 3}],
    ['js', {mainTech : MainTec.JS, techName : 'JavaScript', techCat : TecCat.JSF, techLev : 4}],
    ['ts', {mainTech : MainTec.JS, techName : 'Typescript', techCat : TecCat.JSF, techLev : 4}],
    ['hib', {mainTech : null, techName : 'Hibernate', techCat : TecCat.JF, techLev : 3}],
    ['jsp', {mainTech : null, techName : 'JSP', techCat : TecCat.JF, techLev : 4}],
    ['my', {mainTech : null, techName : 'MySQL', techCat : TecCat.DB, techLev : 4}],
    ['pg', {mainTech : null, techName : 'PostgreSQL', techCat : TecCat.DB, techLev : 4}],
    ['ora', {mainTech : null, techName : 'Oracle', techCat : TecCat.DB, techLev : 3}],
    ['mon', {mainTech : null, techName : 'MongoDB', techCat : TecCat.DB, techLev : 3}],
    ['red', {mainTech : null, techName : 'Redis', techCat : TecCat.DB, techLev : 3}],
    ['a2', {mainTech : MainTec.ANGULAR, techName : 'Angular', techCat : TecCat.JSF, techLev : 4}],
    ['njs', {mainTech : MainTec.JS, techName : 'NodeJS', techCat : TecCat.JSF, techLev : 4}],
    ['vue', {mainTech : MainTec.JS, techName : 'VueJS', techCat : TecCat.JSF, techLev : 3}],
    ['rjs', {mainTech : MainTec.JS, techName : 'ReactJS', techCat : TecCat.JSF, techLev : 3}],
    ['ajs', {mainTech : MainTec.ANGULAR, techName : 'AngularJS', techCat : TecCat.JSF, techLev : 3}],
    ['git', {mainTech : null, techName : 'Git', techCat : TecCat.MISC, techLev : 4}],
    ['tf', {mainTech : null, techName : 'Terraform', techCat : TecCat.MISC, techLev : 3}],
    ['aws', {mainTech : null, techName : 'AWS', techCat : TecCat.MISC, techLev : 3}],
    ['k8s', {mainTech : null, techName : 'Kubernetes', techCat : TecCat.MISC, techLev : 2}],
    ['ocp', {mainTech : null, techName : 'OpenShift 4', techCat : TecCat.MISC, techLev : 3}],
    ['do', {mainTech : null, techName : 'Docker', techCat : TecCat.MISC, techLev : 3}],
    ['va', {mainTech : null, techName : 'Vagrant', techCat : TecCat.MISC, techLev : 2}],
	['so', {mainTech : null, techName : 'Solr', techCat : TecCat.MISC, techLev : 2}],
	['mv', {mainTech : null, techName : 'Maven', techCat : TecCat.MISC, techLev : 4}],
	['gr', {mainTech : null, techName : 'Gradle', techCat : TecCat.MISC, techLev : 3}],
	['ant', {mainTech : null, techName : 'Ant', techCat : TecCat.MISC, techLev : 2}],
    ['co', {mainTech : null, techName : 'Concourse', techCat : TecCat.MISC, techLev : 2}],
    ['je', {mainTech : null, techName : 'Jenkins', techCat : TecCat.MISC, techLev : 3}],
    ['p3', {mainTech : MainTec.PYTHON, techName : 'Python 3', techCat : TecCat.PL, techLev : 3}],
    ['r', {mainTech : null, techName : 'R', techCat : TecCat.PL, techLev : 1}],
    ['c', {mainTech : null, techName : 'C#', techCat : TecCat.PL, techLev : 2}],
    ['php', {mainTech : MainTec.PHP, techName : 'PHP', techCat : TecCat.PL, techLev : 3}],
    ['spa', {mainTech : null, techName : 'Apache Spark', techCat : TecCat.BD, techLev : 2}],
    ['hba', {mainTech : null, techName : 'HBase', techCat : TecCat.BD, techLev : 2}],
    ['had', {mainTech : null, techName : 'Hadoop', techCat : TecCat.BD, techLev : 2}],
    ['kaf', {mainTech : null, techName : 'Kafka', techCat : TecCat.BD, techLev : 2}],
    ['bas', {mainTech : null, techName : 'Bash', techCat : TecCat.PL, techLev : 3}],
]); 

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-printable',
  templateUrl: './printable.component.html',
  styleUrls: ['./printable.component.scss']
})
export class PrintableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

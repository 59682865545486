import { Component, OnInit } from '@angular/core';
import { AppService } from "../app.service";

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {

  language = 'en'
  constructor(private appService: AppService) {

  }

  ngOnInit() {
  }

  setLanguage(language: string) {
    this.appService.setLanugage(language);
    this.language = language;
  }

}

import { Component, OnInit } from '@angular/core';
import { Technology, techMap, TecCat, TecRef } from '../model/tecnologies';
import { AppService } from "../app.service";
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.scss']
})
export class TechnologiesComponent implements OnInit {

  techArray: Technology[];
  catArray: string[];
  tecRefs: TecRef[];
  language = 'en';
  constructor(private appService: AppService) { }

  ngOnInit() {
    this.appService.propertiesObservable.subscribe(() => {
      this.language = this.appService.getLanguage();
      console.log(this.language);
    });

    this.techArray = Array.from(techMap.values());
    this.catArray = Object.values(TecCat) as string[];
    this.appService.getTechRefs().subscribe(data => {
      this.tecRefs = data;
    }, error => {
      console.log(error);
    }, () => {

    });
  }
}

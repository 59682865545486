import {Component, OnInit, Input} from '@angular/core';
import {AppService} from "../app.service";
import {Project} from "../model/project";
import {ProjectTechnology, techMap} from "../model/tecnologies";

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  showAll: boolean = true;
  projectsArray: Project[];
  techArray: ProjectTechnology[];
  @Input() showToggler: boolean = true;

  constructor(private appService: AppService) {
  }

  ngOnInit() {
    this.appService.getTechnologies().subscribe(data => {
      this.techArray = data;
    }, error => {
      console.log(error);
    },() => {
      this.getProjects();
    });

  }

  showDivs() {
    this.showAll = true;
  }

  hideDivs() {
    this.showAll = false;

  }

  private mapTechsToProject() {
    if (this.projectsArray) {
      this.projectsArray.forEach(project => {
        const tech: ProjectTechnology = this.techArray.filter(projectTech => projectTech.id === project.id)[0];
        tech.technologies.forEach(technology => project.technologies.push(techMap.get(technology)));
      })
    }
  }

  private getProjects() {
    this.appService.getProjects().subscribe(data => {
      this.projectsArray = data;
    }, error => {
      console.log(error);
    },() => {
      this.mapTechsToProject();
    });
  }
}

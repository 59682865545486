import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import { About } from './model/about';
export enum PropertyKeyEnum {
  LANGUAGE
}

export class Property {
  key: PropertyKeyEnum;
  value: string;
}
@Injectable({
  providedIn: 'root'
})
@Injectable()
export class AppService{
  private readonly propertiesSubject: BehaviorSubject<Property[]> = new BehaviorSubject<Property[]>([
    { key: PropertyKeyEnum.LANGUAGE, value: 'en' }
  ]);

  // Wird durch pipe "async" subscribed und beim onDestroy automatisch unsubscribed
  public readonly propertiesObservable = this.propertiesSubject.asObservable();

  private get properties(): Property[] {
    return this.propertiesSubject.getValue();
  }

  private set properties(val: Property[]) {
    this.propertiesSubject.next(val);
  }

  constructor(private http: HttpClient) {
      this.getProjects().subscribe(data => {
      });
      this.getTechnologies().subscribe(data => {
      });
  }

  public getProjects(): Observable<any> {
    return this.http.get("./assets/data/projects.json");
  }

  public getTldr(): Observable<any> {
    return this.http.get("./assets/data/tldr.json");
  }

  public getTechnologies(): Observable<any> {
    return this.http.get("./assets/data/techs.json");
  }

  public getTechRefs(): Observable<any> {
    return this.http.get("./assets/data/techRefs.json");
  }

  getProperty(key: PropertyKeyEnum): Property {
    return this.properties.find(property => property.key === key);
  }

  getLanguage(): string {
    return this.getProperty(PropertyKeyEnum.LANGUAGE).value;
  }
  setLanugage(language: string) {
    this.propertyAendern(PropertyKeyEnum.LANGUAGE, language);
  }

  async propertyAendern(key: PropertyKeyEnum, value: string) {
    const property = this.getProperty(key);

    if (property) {
      const index = this.properties.indexOf(property);
      this.properties[index] = { key, value };
      this.properties = [...this.properties];
    }
  }
}

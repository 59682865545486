import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Project} from '../../model/project';
import {MainTec, TecCat} from 'src/app/model/tecnologies';
import {DomSanitizer} from '@angular/platform-browser';
import {AppService} from "../../app.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, OnDestroy {

  private unsubscribeSubject = new Subject<void>();
  @Input() showDiv: boolean;
  @Input() project: Project;
  language = 'en';
  client: string;
  prodDes: string;
  roleDes: string;
  intermediary: string;
  timeSpent: string;
  projects: string;

  constructor(private sanitizer: DomSanitizer, private appService: AppService) {
  }

  ngOnInit() {

    this.appService.propertiesObservable.pipe(takeUntil(this.unsubscribeSubject)).subscribe(() => {
      this.language = this.appService.getLanguage();
      if (this.language === 'en') {
        this.setEN();
      } else if (this.language === 'de') {
        this.setDE();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  setEN(){
    this.client = 'Client';
    this.prodDes = 'Product Description';
    this.roleDes = 'Duties';
    this.intermediary = 'Intermediary';
    this.timeSpent = 'Time Spent';
    this.projects = 'Projects';
  }

  setDE(){
    this.client = 'Kunde';
    this.prodDes = 'Beschreibung Produkt';
    this.roleDes = 'Beschreibung Rolle';
    this.intermediary = 'Dienstleister';
    this.timeSpent = 'Zeit';
    this.projects = 'Projekte';
  }

  getMainTechs(): MainTec[] {
    const mainTechs: MainTec[] = [];
    this.project.technologies.forEach(technology => mainTechs.push(technology.mainTech));

    return mainTechs;
  }

  sanitizedlink() {
    return this.sanitizer.bypassSecurityTrustUrl(this.project.link);
  }

  getYear(id: number) {
    let year: string = id.toString().substring(0, 4)
    return year
  }

  getClass(techCat: TecCat) {
    switch(techCat) {
      case TecCat.JF:
        return "badges-java";
      case TecCat.JSF:
        return "badges-js";
      case TecCat.MISC:
        return "badges-ci";
      case TecCat.DB:
        return "badges-db";
      case TecCat.PL:
        return "badges-prog";
      default:
        return "badges-prog";
    }
  }
}

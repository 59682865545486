import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectsComponent } from './projects/projects.component';
import { TechnologiesComponent } from './technologies/technologies.component';
import {AboutMeComponent} from "./about-me/about-me.component";
import { PrintableComponent } from './printable/printable.component';

const routes: Routes = [
  
  { path: '', component: AboutMeComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'technologies', component: TechnologiesComponent },
  { path: 'aboutMe', component: AboutMeComponent},
  { path: 'printable', component: PrintableComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from '../app.service';
import { About, Job, Lan, TldrTech } from '../model/about';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss']
})
export class AboutMeComponent implements OnInit , OnDestroy {

  private unsubscribeSubject = new Subject<void>();
  tldr: About;
  selectedLanguage = "en"
  title: string;
  sub: string;
  titleLan: string;
  titleText: string;
  titleTech: string;
  text: string[];
  languages: Lan[];
  chrono: Job[] = [];
  techs: TldrTech[] = [];
  isPrintable = false;

  constructor(private appService: AppService) {
  }

  ngOnInit() {
    this.isPrintable = window.location.href.indexOf('printable') !== -1;
    this.appService.getTldr().subscribe(data => {
      this.tldr = data
      }, error => {
        console.log(error);
      },() => {
        this.updateText();
      });

    this.appService.propertiesObservable.pipe(takeUntil(this.unsubscribeSubject)).subscribe(() => {
      this.selectedLanguage = this.appService.getLanguage();
      if (this.tldr) {
        this.updateText()
      }
    });
  }

  private updateText() {
    this.title = this.tldr.title;
    this.sub = this.tldr.sub;
    this.chrono = this.tldr.chrono;
    this.techs = this.tldr.techs;
    if (this.selectedLanguage === "en") {
      this.titleLan = this.tldr.titleLan;
      this.text = this.tldr.text;
      this.titleText = this.tldr.titleText;
      this.titleTech = this.tldr.titleTech;
      this.languages = this.tldr.languages;
    } else if (this.selectedLanguage === "de") {
        this.titleLan = this.tldr.titleLan_de;
        this.titleText = this.tldr.titleText_de;
        this.text = this.tldr.text_de;
        this.languages = this.tldr.languages_de;
        this.titleTech = this.tldr.titleTech_de;
    }    
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

}
